import tailwindColors from '../../../../../config/colors'
import { ScaleId } from '../types'

export interface ISettings {
  padding: { top: number; right: number; bottom: number; left: number }
  background: string
  industry: ISettingsSection
  sector: ISettingsSection
  scaleFontSizes: Record<number, number[]>
  fontSizePadding: Record<number, number>
  fontSizePairs: Record<number, number[]>
}

export interface ISettingsSection {
  padding: { top: number; right: number; bottom: number; left: number }
  background: string | null
  border: string | null
  header: ISettingsSectionHeader
  hover?: ISettingsSectionHeader
}

export interface ISettingsSectionHeader extends ISettingsSectionFont {
  background: string | null
  border: string | null
  height: number
  margin: { top: number; right: number; bottom: number; left: number }
}

export interface ISettingsSectionFont {
  color: string
  fontSize: number
  fontWeight: 'bold' | 'normal'
  padding: { top: number; right: number; left: number }
}

export const scaleMinMax: Record<ScaleId, any> = {
  [ScaleId.IntradayReduced]: [-1, 1],
  [ScaleId.DayPerf]: [-3, 3],
  _5: [-6, 6],
  _10: [-10, 10],
  _15: [-18, 18],
  _20: [-24, 24],
  _25: [-30, 30],
  _relvol: [0, 3],
  _pe: [0, 30],
  _fpe: [0, 30],
  _peg: [0, 3],
  _ps: [0, 5],
  _pb: [0, 5],
  _div: [0, 5],
  _eps5y: [-50, 50],
  _short: [0, 15],
  _rec: [5, 1],
  _earndate: [30, -30],
  // Portfolio — values are just placeholders, the real value is computed from data
  _portfolioPct: [-1, 1],
  _portfolioUsd: [-1, 1],
  _percentHundred: [-100, 100],
}

export const scaleStepFormat: Record<ScaleId, any> = {
  [ScaleId.IntradayReduced]: '%N%',
  [ScaleId.DayPerf]: '%N%',
  _5: '%N%',
  _10: '%N%',
  _15: '%N%',
  _20: '%N%',
  _25: '%N%',
  _relvol: '%N',
  _pe: '%N',
  _fpe: '%N',
  _peg: '%N',
  _ps: '%N',
  _pb: '%N',
  _div: '%N%',
  _eps5y: '%N%',
  _short: '%N%',
  _rec: { 1: '%N Strong Buy', 2: '%N Buy', 3: '%N Hold', 4: '%N Sell', 5: '%N Strong Sell' },
  _earndate: {
    '-30': '%N Days',
    '-15': '%N Days',
    '-1': 'After Earnings',
    1: 'Before Earnings',
    15: '%N Days',
    30: '%N Days',
  },
  _portfolioPct: '%N%',
  _portfolioUsd: '$%N',
  _percentHundred: '%N%',
}

export const Settings: ISettings = {
  padding: { top: 0, right: 0, bottom: 1, left: 1 },
  background: tailwindColors.finviz['blue-gray'],
  sector: {
    padding: { top: 0, right: 1, bottom: 1, left: 1 },
    background: null,
    border: null,
    header: {
      color: '#fff',
      background: null,
      border: null,
      height: 14.5,
      fontSize: 13,
      fontWeight: 'bold',
      margin: { top: 0, right: 0, bottom: 0, left: 0 },
      padding: { top: -1, right: 4, left: 4 },
    },
  },
  industry: {
    padding: { top: 0, right: 1, bottom: 1, left: 1 },
    background: null,
    border: null,
    header: {
      color: '#fff',
      background: null,
      border: tailwindColors.finviz['blue-gray'],
      height: 12,
      fontSize: 10,
      fontWeight: 'normal',
      margin: { top: 0, right: 0.5, bottom: 0, left: -0.5 },
      padding: { top: -0.5, right: 4, left: 4 },
    },
    hover: {
      color: '#6b5811',
      background: '#ffd614',
      border: '#ffd614',
      height: 12,
      fontSize: 10,
      fontWeight: 'normal',
      margin: { top: 0, right: 0, bottom: 0, left: 0 },
      padding: { top: -0.5, right: 3, left: 4 },
    },
  },
  scaleFontSizes: {
    1: [36, 30, 24, 20, 18, 14, 13, 12, 11, 10, 9, 8],
    1.5: [36, 30, 24, 20, 18, 14, 13, 12, 11, 10, 9, 8, 6, 4],
    2.25: [36, 30, 24, 20, 18, 14, 13, 12, 11, 10, 9, 8, 6, 4],
    3: [36, 30, 24, 20, 18, 14, 13, 12, 11, 10, 9, 8, 6, 4],
  },
  fontSizePadding: {
    36: 30,
    30: 25,
    24: 20,
    20: 14,
    18: 12,
    14: 9,
    13: 7,
    12: 7,
    11: 4,
    10: 3,
    9: 3,
    8: 2.5,
    6: 0,
    4: 1,
  },
  fontSizePairs: {
    36: [18, 14, 13, 12],
    30: [14, 13, 12, 11, 10],
    24: [14, 13, 12, 11, 10],
    20: [12, 11, 10, 9, 8, 6],
    18: [11, 10, 9, 8, 6],
    14: [9, 8, 6, 4],
    13: [9, 8, 6, 4],
    12: [9, 8, 6, 4],
    11: [8, 6, 4],
    10: [10, 9, 8, 6, 4],
    9: [9, 8, 6, 4],
    8: [8, 6, 4],
    6: [6, 4],
    4: [4],
  },
}

export const SettingsSmall: ISettings = {
  ...Settings,
  padding: { top: 0, right: 0, bottom: 0, left: 0 },
  background: '#343842',
  sector: {
    ...Settings.industry,
    padding: { top: 0.5, right: 0, bottom: 0, left: 2 },
    border: '#e0e0e0',
    header: {
      ...Settings.industry.header,
      margin: { top: 0, right: 0.5, bottom: 0, left: -1.5 },
      padding: { top: 0.5, right: 1, left: 1 },
      border: '#343842',
      fontSize: 9,
    },
  },
  industry: {
    ...Settings.industry,
    padding: { top: 0, right: 1, bottom: 1, left: 0 },
    header: {
      ...Settings.industry.header,
      height: 0,
    },
  },
  scaleFontSizes: { ...Settings.scaleFontSizes, 1: [14, 12, 11, 8, 6] },
  fontSizePadding: { 14: 5, 12: 2, 11: 2, 8: 2, 6: 0, 4: 0 },
  fontSizePairs: {
    14: [9, 8, 6],
    13: [9, 8, 6],
    12: [6],
    11: [6],
    10: [6],
    9: [6],
    8: [6],
    6: [6],
  },
}

export const SettingsSmallDark: ISettings = {
  ...SettingsSmall,
  sector: {
    ...SettingsSmall.sector,
    background: tailwindColors.gray[800],
    border: tailwindColors.gray[800],
    header: {
      ...SettingsSmall.sector.header,
      padding: { top: 0.5, right: 2, left: 2 },
      margin: { top: 0, right: 1.5, bottom: 0, left: -0.5 },
      border: tailwindColors.gray[800],
    },
  },
  industry: {
    ...SettingsSmall.industry,
    header: {
      ...SettingsSmall.industry.header,
      border: tailwindColors.gray[800],
    },
  },
}

export const SettingsSmallGeo: ISettings = {
  ...Settings,
  padding: { top: 0, right: 0, bottom: 0, left: 1 },
  background: '#a7c4d3',
  industry: {
    ...Settings.industry,
    background: '#e0e0e0',
    header: {
      ...Settings.industry.header,
      height: 9,
      fontSize: 8,
      border: '#e0e0e0',
      margin: { top: -0.5, right: 0.5, bottom: 0, left: -0.5 },
      padding: { top: -1, right: 0, left: 1 },
    },
  },
  scaleFontSizes: { ...Settings.scaleFontSizes, 1: [14, 12, 11, 8, 6] },
  fontSizePadding: { 14: 5, 12: 2, 11: 2, 8: 1, 6: 0, 4: 0 },
  fontSizePairs: {
    14: [9, 8, 6],
    13: [9, 8, 6],
    12: [6],
    11: [6],
    10: [6],
    9: [6],
    8: [6],
    6: [6],
  },
}

export const SettingsSmallGeoDark: ISettings = {
  ...SettingsSmallGeo,
  background: tailwindColors.gray[800],
  sector: {
    ...SettingsSmallGeo.sector,
    background: tailwindColors.gray[800],
    border: tailwindColors.gray[800],
  },
  industry: {
    ...SettingsSmallGeo.industry,
    border: tailwindColors.gray[800],
    background: tailwindColors.gray[800],
    header: {
      ...SettingsSmallGeo.industry.header,
      border: tailwindColors.gray[800],
    },
  },
}

export const SettingsScreener: ISettings = {
  ...Settings,
  padding: { top: 0, right: 0, bottom: 0, left: 0 },
  sector: {
    ...Settings.sector,
    padding: { top: 0, right: 0, bottom: 0, left: 1 },
    background: '#363a46',
    border: '#d3d3d3',
    header: {
      ...Settings.sector.header,
      height: 20,
      padding: { top: 2.5, right: 4, left: 4 },
    },
  },
  industry: {
    ...Settings.industry,
    header: {
      ...Settings.industry.header,
      border: '#363a46',
    },
  },
}

export const SettingsScreenerDark: ISettings = {
  ...SettingsScreener,
  sector: {
    ...SettingsScreener.sector,
    background: tailwindColors.gray[800],
    border: tailwindColors.gray[800],
  },
  industry: {
    ...SettingsScreener.industry,
    header: {
      ...SettingsScreener.industry.header,
      border: tailwindColors.gray[800],
    },
  },
}
