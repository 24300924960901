import * as React from 'react'

import { SortDirection, SortableValue } from '../types'
import { SortByColumnOptions, sortByColumn } from '../util'

export interface SortableColumn<IdType, DataType> {
  id: IdType

  compoundSort?: IdType[]

  /**
   * Provide value calculation for sort when value is computed in column renderer
   */
  getValueForSort?: (row: DataType) => SortableValue
}

export function isSortableColumnShape<IdType, DataType>(
  maybeColumn?: unknown
): maybeColumn is SortableColumn<IdType, DataType> {
  return typeof maybeColumn === 'object' && maybeColumn?.hasOwnProperty?.('id') === true
}

export interface SortByShape<ColumnType> {
  column: ColumnType
  direction: SortDirection
}

export function isSortByShape<ColumnType>(maybeSort?: unknown): maybeSort is SortByShape<ColumnType> {
  return maybeSort?.hasOwnProperty?.('column') === true && maybeSort?.hasOwnProperty?.('direction') === true
}

/**
 * Default value getter which gets row data by `getValueForSort` defined on the column
 * If no sort value getter is provided, it falls back to getting data by column.id or returns the whole row
 */
export function getDefaultValueGetter<DataType, SortBy>(column: SortBy) {
  return (row: DataType): SortableValue => {
    let value = null

    if (isSortableColumnShape(column)) {
      if (typeof column.getValueForSort === 'function') value = column.getValueForSort(row)
      else if (typeof row === 'object') value = row?.[column.id as keyof typeof row]
    } else {
      value = row
    }

    return (value as SortableValue) ?? null
  }
}

interface SortDataOptions<DataType, ColumnType, SortBy> {
  columns: readonly ColumnType[]
  data: DataType[]
  sortBy?: SortBy
  valueGetter?: (column: ColumnType) => (row: DataType) => SortableValue
  options?: SortByColumnOptions
}

export function sortDataByColumn<
  IdType,
  DataType,
  ColumnType extends SortableColumn<IdType, DataType>,
  SortBy extends SortByShape<ColumnType>,
>({
  columns,
  data,
  sortBy,
  valueGetter = getDefaultValueGetter,
  options,
}: SortDataOptions<DataType, ColumnType, SortBy>) {
  if (!sortBy?.column) return [...data]

  let sortedColumns: SortByShape<ColumnType>[] = [sortBy]
  if (sortBy.column.compoundSort) {
    sortedColumns = sortBy.column.compoundSort.map((compoundColumn) => ({
      column: columns.find((column) => column.id === compoundColumn)!,
      direction: sortBy.direction,
    }))
  }

  return [...data].sort((_a, _b) => {
    for (const columnSortBy of sortedColumns) {
      const a = valueGetter(columnSortBy.column)(_a)
      const b = valueGetter(columnSortBy.column)(_b)
      const sortResult = sortByColumn({ ...options, a, b, direction: columnSortBy.direction })

      if (sortResult === 0) continue

      return sortResult
    }

    return 0
  })
}

export function useSortData<
  IdType,
  DataType,
  ColumnType extends SortableColumn<IdType, DataType>,
  SortBy extends SortByShape<ColumnType>,
>(options: SortDataOptions<DataType, ColumnType, SortBy>) {
  return React.useMemo(
    () => {
      if (options.data.length === 0) return options.data
      return sortDataByColumn(options)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options.columns, options.data, options.sortBy, options.valueGetter, options.options]
  )
}
