import { ReactNode } from 'react'

export enum MapTypeId {
  Sector = 'sec',
  World = 'geo',
  SectorFull = 'sec_all',
  ETF = 'etf',
  Screener = 'screener',
  Portfolio = 'portfolio',
  ETFHoldings = 'etf_holdings',
  ManagersAndFunds = 'managers_and_funds',
}

export enum MapSubtypeId {
  IntradayPerf1m = 'i1',
  IntradayPerf2m = 'i2',
  IntradayPerf3m = 'i3',
  IntradayPerf5m = 'i5',
  IntradayPerf10m = 'i10',
  IntradayPerf15m = 'i15',
  IntradayPerf30m = 'i30',
  IntradayPerf1h = 'h',
  IntradayPerf2h = '2h',
  IntradayPerf4h = '4h',
  IntradayPerfAH = 'ah',
  DayPerf = 'd1',
  WeekPerf = 'w1',
  MonthPerf = 'w4',
  ThreeMonthPerf = 'w13',
  SixMonthPerf = 'w26',
  YearPerf = 'w52',
  YearToDatePerf = 'ytd',
  RelativeVolume = 'relvol',
  PriceToEarnings = 'pe',
  ForwardPriceToEearnings = 'fpe',
  PriceEarningsToGrowth = 'peg',
  PriceToSellsRatio = 'ps',
  PriceToBookRatio = 'pb',
  DividendYield = 'div',
  EPSGrowth5y = 'eps5y',
  FloatShort = 'short',
  AnalystRecommendation = 'rec',
  EarningsPerf = 'earnperf',
  EarningsDate = 'earndate',
  // Portfolio
  PortfolioGainPct = 'portfolioGainPct',
  PortfolioGainUsd = 'portfolioGainUsd',
  PortfolioChangePct = 'portfolioChangePct',
  PortfolioChangeUsd = 'portfolioChangeUsd',
  ManagersAndFunds = 'managersAndFunds',
}

export enum ScaleId {
  IntradayReduced = 'intradayReduced',
  DayPerf = 'default',
  WeekPerf = '_5',
  MonthPerf = '_10',
  ThreeMonthPerf = '_15',
  SixMonthPerf = '_20',
  YearPerf = '_25',
  RelativeVolume = '_relvol',
  PriceToEarnings = '_pe',
  ForwardPriceToEearnings = '_fpe',
  PriceEarningsToGrowth = '_peg',
  PriceToSellsRatio = '_ps',
  PriceToBookRatio = '_pb',
  DividendYield = '_div',
  EPSGrowth5y = '_eps5y',
  FloatShort = '_short',
  AnalystRecommendation = '_rec',
  EarningsDate = '_earndate',
  // Portfolio
  PortfolioPct = '_portfolioPct',
  PortfolioUsd = '_portfolioUsd',
  PercentHundred = '_percentHundred',
}

export const enum NodeDepth {
  Root,
  Sector,
  Industry,
  Node,
}

export interface MapDataRow {
  name: string
  perf?: number
  x: number
  y: number
  dx: number
  dy: number
  data: any
  depth: NodeDepth
}

export interface MapDataRoot {
  name: string
  children: MapDataSector[]
  duplicateTickers: number
  hash: string
}

export interface MapDataSector extends MapDataRow {
  children: MapDataIndustry[]
  parent: MapDataRoot
}

export interface MapDataIndustry extends MapDataRow {
  children: MapDataNode[]
  parent: MapDataSector
}

export interface MapDataNode extends MapDataRow {
  parent: MapDataIndustry
  additional?: string
  description: string
  perfText: string
  value: number
}

export interface MapData {
  sectors: MapDataSector[]
  industries: MapDataIndustry[]
  nodes: MapDataNode[]
}

export type PerfData = {
  nodes: Record<string, number> | Array<MapDataNode>
  additional: Record<string, string>
  subtype: MapSubtypeId
  version?: number
  hash: string
}

export interface MapSubtype {
  value: MapSubtypeId
  label: string
  scaleId: ScaleId
  tooltip: string
  countIndustryPerf: boolean
  countSectorPerf: boolean
}

export interface MapType {
  name: string
  pageTitle: string
  nameShort?: string
  description: string
  type: MapTypeId
  subtypes: Array<{ items: MapSubtype[] }>
}

export interface Scale {
  name: string
  tooltip: ReactNode
  id: ScaleId
}
