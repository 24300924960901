import * as React from 'react'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import { usePrevious } from '../../hooks/use-previous'
import { SortByShape, SortableColumn, useSortData } from '../../hooks/use-sort-data'
import { SortDirection, SortableValue } from '../../types'
import { SortByColumnOptions } from '../../util'
import { encodeSort, getDefaultSort, getDefaultSortFromUrl, saveSortToUrl } from './utils'

interface UseTableSortProps<
  IdType,
  DataType,
  ColumnType extends SortableColumn<IdType, any>,
  DefaultSort extends ColumnType['id'] | SortByShape<ColumnType> | null,
> {
  data: DataType[]
  columns: readonly ColumnType[]

  /**
   * You can either provide id of the column or whole shape
   * defaultSort: { column: columns[0], direction: -1 }
   * If your ids are constants (or enums), you will get name suggestions
   */
  defaultSort?: DefaultSort

  /**
   * Custom value getter. By default, these can be defined on every column using `getValueForSort`
   */
  valueGetter?: (column: ColumnType) => (row: DataType) => SortableValue

  /**
   * Additional options for sorting, including ignoring null values or sorting null as last
   */
  options?: SortByColumnOptions

  /**
   * Value which reinits defaults if changed
   */
  key?: string | number
}

/**
 * This hook has multiple
 */
export function useTableSort<
  IdType,
  DataType,
  ColumnType extends SortableColumn<IdType, any>,
  DefaultSort extends ColumnType['id'] | SortByShape<ColumnType> | null,
  SortType extends DefaultSort extends null ? ColumnType | null : ColumnType,
>({
  data,
  columns,
  defaultSort,
  valueGetter,
  options,
  key,
}: UseTableSortProps<IdType, DataType, ColumnType, DefaultSort>) {
  const [sort, setSort] = React.useState(() => getDefaultSort(columns, defaultSort) as SortByShape<SortType>)
  const isMountedRef = React.useRef(false)

  React.useMemo(() => {
    if (!isMountedRef.current) {
      isMountedRef.current = true
      return
    }

    setSort(getDefaultSort(columns, defaultSort) as SortByShape<SortType>)
    // We want to run it only on key change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key])

  const changeSort = React.useCallback((newSort: SortType, direction?: SortDirection) => {
    setSort((prevSort) => {
      if (newSort && direction) {
        return { column: newSort, direction }
      }

      if (newSort && prevSort.column?.id === newSort.id) {
        return { ...prevSort, direction: prevSort.direction * -1 }
      }

      return { column: newSort, direction: SortDirection.Ascending }
    })
  }, [])

  const sortedData = useSortData({
    columns,
    data,
    sortBy: sort as unknown as SortByShape<ColumnType>,
    valueGetter,
    options,
  })

  return {
    data: sortedData,
    sort,
    changeSort,
  }
}

interface UseTableSortWithAutoupdateProps<
  IdType,
  DataType,
  ColumnType extends SortableColumn<IdType, any>,
  DefaultSort extends ColumnType['id'] | SortByShape<ColumnType> | null,
> extends UseTableSortProps<IdType, DataType, ColumnType, DefaultSort> {
  tableOrder: string
}

/**
 * This hook will monitor changes in `tableOrder` and update its sort order by it
 * Useful if we need to keep sort order of multiple tables in sync
 */
export function useTableSortWithAutoupdate<
  IdType,
  DataType,
  ColumnType extends SortableColumn<IdType, any>,
  DefaultSort extends ColumnType['id'] | SortByShape<ColumnType> | null,
>({
  data,
  columns,
  defaultSort,
  valueGetter,
  options,
  tableOrder,
}: UseTableSortWithAutoupdateProps<IdType, DataType, ColumnType, DefaultSort>) {
  const { data: sortedData, changeSort, sort } = useTableSort({ data, columns, defaultSort, valueGetter, options })
  const previousTableOrder = usePrevious(tableOrder)

  useEffect(() => {
    if (previousTableOrder && tableOrder !== previousTableOrder) {
      let sortName
      let sortDirection
      if (tableOrder.startsWith('-')) {
        sortName = tableOrder.slice(1)
        sortDirection = SortDirection.Descending
      } else {
        sortName = tableOrder
        sortDirection = SortDirection.Ascending
      }
      const column = columns.find((column) => column.id === sortName)
      if (column) {
        changeSort(column, sortDirection)
      }
    }
  }, [changeSort, columns, previousTableOrder, tableOrder])

  return {
    data: sortedData,
    sort,
    changeSort,
  }
}

interface UseTableSortWithUrlProps<
  IdType,
  DataType,
  ColumnType extends SortableColumn<IdType, any>,
  DefaultSort extends ColumnType['id'] | SortByShape<ColumnType> | null,
> extends UseTableSortProps<IdType, DataType, ColumnType, DefaultSort> {
  /**
   * When set, the sorting will be persisted in the URL. Loading the
   *
   * @default "o"
   */
  urlPersistKey?: string

  /**
   * Key to get value of the URL sort from a column
   * @default "id"
   */
  idKey?: keyof ColumnType
}

export function useTableSortWithUrl<
  IdType,
  DataType,
  ColumnType extends SortableColumn<IdType, any>,
  DefaultSort extends ColumnType['id'] | SortByShape<ColumnType> | null,
  SortType extends DefaultSort extends null ? ColumnType | null : ColumnType,
>({
  data,
  columns,
  defaultSort,
  valueGetter,
  urlPersistKey = 'o',
  idKey = 'id',
  options,
  key,
}: UseTableSortWithUrlProps<IdType, DataType, ColumnType, DefaultSort>) {
  const [searchParams, setSearchParams] = useSearchParams()
  const defaultSortRef = React.useRef(
    urlPersistKey && typeof defaultSort === 'string'
      ? getDefaultSortFromUrl(columns, searchParams.get(urlPersistKey) ?? defaultSort, idKey)
      : getDefaultSort(columns, defaultSort)
  )
  const {
    data: sortedData,
    changeSort: changeSortState,
    sort,
  } = useTableSort({
    data,
    columns,
    defaultSort: defaultSortRef.current.column === null ? null : (defaultSortRef.current as SortByShape<ColumnType>),
    valueGetter,
    options,
    key,
  })

  const changeSort = React.useCallback(
    (newSort: SortType, additionalUrlParams?: URLSearchParams) => {
      if (urlPersistKey) {
        const defaultSort: ColumnType | null = defaultSortRef.current.column
        setSearchParams(
          (prev) => {
            const newSearchParams = saveSortToUrl(
              prev,
              urlPersistKey,
              newSort,
              idKey,
              defaultSort === null ? null : encodeSort(`${defaultSort[idKey]}`, defaultSortRef.current.direction)
            )
            additionalUrlParams?.forEach((value, key) => {
              newSearchParams.set(key, value)
            })
            return newSearchParams
          },
          {
            preventScrollReset: true,
          }
        )
      }

      changeSortState(newSort)
    },
    [idKey, setSearchParams, urlPersistKey, changeSortState]
  )

  /**
   * Update sort from url
   */
  React.useEffect(() => {
    if (!urlPersistKey) return

    const newSort = searchParams.get(urlPersistKey)
    if (newSort) {
      const sort = getDefaultSortFromUrl(columns, newSort, idKey)
      changeSortState(sort.column, sort.direction)
    }
  }, [columns, idKey, searchParams, urlPersistKey, changeSortState])

  return {
    data: sortedData,
    sort,
    changeSort,
  }
}
